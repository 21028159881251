.map-card{
  @apply relative pt-8;
}
.map-body{
  @apply w-[60%] relative mx-auto;
}
.map_chart .regions {
  fill: #69c0ff;
  cursor: pointer;
  stroke: #1d617a;
}

.map_chart .regions:hover {
  fill: #1d617a !important;
}
.regions-group:hover .regions {
  fill: #1d617a !important;
}

.map_chart .regions_active {
  fill: #1d617a !important;
  cursor: pointer;
}
.map-title {
  font: 500 12px serif;
  cursor: pointer;
}
.region-popup-data {
  position: absolute !important;
  top: 10px;
  right: 10px;
  background-color: #f6f9fb !important;
  box-shadow: 0 10px 20px rgba(29, 97, 122, 0.15);
  width: 250px !important;
  border-radius: 16px !important;
  padding: 10px !important;
}
